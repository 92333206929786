// Import Modules
const loginModule = () => import('@/modules/Login/index.vue')

// Export
export default [
    // Dashboard
    {
        path: '/',
        component: loginModule,
        // meta: { auth: true },
        name: 'home'
    },
    { path: '*', redirect: '/' }
]