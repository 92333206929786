import { IBase } from "@/models/IBase";

export default function getBaseURL() {
  const storedUser = localStorage.getItem("user");

  let base: IBase = {
    baseURL: "",
    clientId: 0,
    userId: 0,
  };

  if (storedUser) {
    let user = JSON.parse(storedUser ? storedUser : "");
    if (user && user.token) {
      base = {
        baseURL: process.env.VUE_APP_BACKEND_URL,
        clientId: user.clientId,
        userId: user.userId,
      };

      return base;
    } else {
        console.log(process.env.VUE_APP_BACKEND_URL);
      base = {
        baseURL: process.env.VUE_APP_BACKEND_URL,
        clientId: 0,
        userId: 0,
      };
      return base;
    }
  }
  else{
    base = {
        baseURL: process.env.VUE_APP_BACKEND_URL,
        clientId: 0,
        userId: 0,
      };
      return base;
  }
}
