import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ClearTextBoxField extends Vue{


    @Prop({ default: '' })
    placeholder!: string

    @Prop({ default: '' })
    value!: string

    @Prop({ default: 50 })
    maxlength!: Number


    clearText()
    {
        this.value = ""
    }
}