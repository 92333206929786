import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import privateRoutes from './PrivateRoutes'
import publicRoutes from './PublicRoutes'

Vue.use(VueRouter)

// const routes: Array<RouteConfig> = [
//   {
//     path: '/',
//     name: 'Home',
//     component: () => import(/* webpackChunkName: "about" */ '@/modules/Login/index.vue'),
//   },
//   {
//     path: '/admin/dashboard',
//     name: 'admin.dashboard',
//     component: () => import(/* webpackChunkName: "about" */ '@/modules/Dashboard/index.vue'),
//   }
// ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...privateRoutes, ...publicRoutes]
})
// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   const { authorize } = to.meta;
//   const currentUser = authenticationService.currentUserValue;

//   if (authorize) {
//       if (!currentUser) {
//           // not logged in so redirect to login page with the return url
//           return next({ path: '/login', query: { returnUrl: to.path } });
//       }

//       // check if route is restricted by role
//       if (authorize.length && !authorize.includes(currentUser.role)) {
//           // role not authorised so redirect to home page
//           return next({ path: '/' });
//       }
//   }

//   next();
// })
export default router
