import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("UserCount", function(value) {
  if (value == null) {
    return "0 UserCount";
  } else {
    return value + " User(s)";
  }
});

Vue.filter("Currency", function(value) {
  if (value == null) {
    return "PKR " + parseFloat(value).toFixed(2);
  } else {
    return "PKR " + parseFloat(value).toFixed(2);
  }
});

export default class DateFilter {}
