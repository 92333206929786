import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AlertBox extends Vue{


    @Prop({ default: '' })
    title!: string

    @Prop({ default: '' })
    content!: string

    @Prop({ default: '' })
    class!: string


}