import GroupMembers from '@/modules/GroupMembers/GroupMembers'

// Import Modules
const dashboardModule = () => import('@/modules/Dashboard/index.vue')
const clientModule = () => import('@/modules/Clientmodule/index.vue')
const clientInsertModule = () => import('@/modules/Clientmodule/Add/index.vue')
const branchModule = () => import('@/modules/Branches/index.vue')
const groupModule = () => import('@/modules/ClientGroups/index.vue')
const cpackageModule = () => import('@/modules/ClientPackages/index.vue')
const cUsersModule = () => import('@/modules/ClientUsers/index.vue')
const groupMemberModule = () => import('@/modules/GroupMembers/index.vue')
const packageModule = () => import('@/modules/AdminPackages/index.vue')
const paymentModule = ()=> import('@/modules/Payments/index.vue')
const groupMessage = ()=> import('@/modules/GroupMessages/index.vue')
const users = ()=> import('@/modules/Users/index.vue')

// Export
export default [
    // Dashboard
    {
        path: '/admin/dashboard',
        component: dashboardModule,
        // meta: { auth: true },
        name: 'admin.dashboard'
    },
    {
        path: '/admin/clients',
        component: clientModule,
        // meta: { auth: true },
        name: 'admin.clients'
    },
    {
        path: '/admin/addclient',
        component: clientInsertModule,
        // meta: { auth: true },
        name: 'admin.clientInsertModule'
    },
    {
        path: '/admin/branch',
        component: branchModule,
        // meta: { auth: true },
        name: 'admin.branch'
    },
    {
        path: '/admin/group',
        component: groupModule,
        // meta: { auth: true },
        name: 'admin.group'
    },
    {
        path: '/admin/client-packages',
        component: cpackageModule,
        // meta: { auth: true },
        name: 'admin.clientPackage'
    },
    {
        path: '/admin/client-users',
        component: cUsersModule,
        // meta: { auth: true },
        name: 'admin.clientUsers'
    },
    {
        path: '/admin/group-members',
        component: groupMemberModule,
        // meta: { auth: true },
        name: 'admin.groupmembers'
    },
    {
        path: '/admin/packages',
        component: packageModule,
        // meta: { auth: true },
        name: 'admin.packages'
    },
    {
        path: '/admin/payments',
        component: paymentModule,
        // meta: { auth: true },
        name: 'admin.payments'
    },
    {
        path: '/admin/group-msg',
        component: groupMessage,
        // meta: { auth: true },
        name: 'admin.groupMsg'
    },
    {
        path: '/assets/documents/UserUploadTemplate.xlsx',
        //component: groupMessage,
        // meta: { auth: true },
        name: 'admin.template'
    },
    {
        path: '/admin/users',
        component: users,
        // meta: { auth: true },
        name: 'admin.users'
    },
    { path: '*', redirect: '/' }
]