import Vue from "vue";
import App from "@/modules/App/index.vue";
import "./registerServiceWorker";
import router from "./routes";
import store from "./store";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import Vuesax from "vuesax";
//import { vsButton,vsSideBar, vsPopup } from 'vuesax'
import "material-icons/iconfont/material-icons.css";
import "vuesax/dist/vuesax.css";
import VueProgressBar from "vue-progressbar";
import { required } from "vee-validate/dist/rules";
import DateFilter from "./helper/DateFilter";
import PassowordFiled from "./components/PasswordInput/index.vue"
import ClearTextBoxField from "./components/ClearTextBox/index.vue"
import AlertBox from "./components/AlertBox/index.vue"


extend("required", {
  ...required,
  message: "{_field_} is required",
});

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
Vue.config.productionTip = false;

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("PassowordFiled", PassowordFiled)
Vue.component("ClearTextBoxField", ClearTextBoxField)
Vue.component("AlertBox", AlertBox)

const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(Vuesax);
// Vue.use(vsButton)
// Vue.use(vsSideBar)
// Vue.use(vsPopup)
Vue.use(VueProgressBar, options);

new Vue({
  router,
  store,
  filters: {
    DateFilter,
  },
  render: (h) => h(App),
}).$mount("#app");
