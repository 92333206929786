import { IBase } from "@/models/IBase";
import axios from "axios";
import getBaseURL from "./Base";

let base: any = getBaseURL();


const API_URL = base.baseURL //process.env.VUE_APP_BACKEND_URL;
console.log(API_URL);
const instance = axios.create({
  baseURL: API_URL,
});

class AuthService {
  login(userName: string, password: string) {
    return axios
      .post(
        API_URL + "Account/login",
        {
          UserEmail: userName,
          Password: password,
        },
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.data.result.token) {
          localStorage.setItem("user", JSON.stringify(response.data.result));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
