import { Component, Prop, Vue } from 'vue-property-decorator'


@Component
export default class PasswordField extends Vue{

    showPassword = false
    currentValue = ""

    @Prop({ default: '' })
    placeholder!: string

    @Prop({ default: '' })
    value!: string

    @Prop({ default: 50 })
    maxlength!: Number


    togglePassowrd()
    {
        this.currentValue = this.value
        this.showPassword = !this.showPassword
        this.value = this.currentValue
    }

    // showPassword() {
    //     this.visibility = 'text';
    // }

    // hidePassword() {
    //     this.visibility = 'password';
    // }
}