import { render, staticRenderFns } from "./AlertBox.html?vue&type=template&id=ce0d65fa&scoped=true&"
import script from "./AlertBox.ts?vue&type=script&lang=ts&"
export * from "./AlertBox.ts?vue&type=script&lang=ts&"
import style0 from "./AlertBox.css?vue&type=style&index=0&id=ce0d65fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce0d65fa",
  null
  
)

export default component.exports